import React from "react";

import MainScreen from "./screens/MainScreen";

function App() {
  return (
    <>
      <MainScreen />
    </>
  );
}

export default App;
