import React from "react";
import { MdAddToPhotos } from "react-icons/md";

function NotifyAdd({ notification }) {
  return (
    <div className="m-2 rounded-lg border-2 border-blue-500 bg-blue-200">
      <div className="flex items-center gap-2 px-3 py-1 border-b-2 border-blue-500">
        <MdAddToPhotos className="text-blue-500 text-lg" />
        <p className="text-sm font-bold text-blue-500">New File Added</p>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center gap-2 px-3 pt-2">
        <p className="text-base">
          <span>New File </span>
          <span className="font-semibold underline">
            {notification?.productName}
          </span>
          <span> was added by </span>
          <span className="font-semibold underline">{notification?.by}</span>
        </p>
        <p className="text-[10px] font-mono md:text-right md:w-5/12">
          {notification?.on?.toDate()?.toString()}
        </p>
      </div>
      <p className="px-3 pb-1 text-xs">
        category: <span className="font-bold">{notification?.category}</span>
      </p>
      <p className="px-3 pb-1 text-xs">
        price: <span className="font-bold">{notification?.price}</span>
      </p>
    </div>
  );
}

export default NotifyAdd;
