import React from "react";
import { NavLink } from "react-router-dom";
import { MdDashboard, MdLogout, MdNotifications } from "react-icons/md";

const AppBar = () => {
  const activeLink =
    "p-2 border border-blue-500 text-white rounded-xl bg-blue-500 flex gap-3 transition-all duration-300 w-full";
  const nonActiveLink =
    "p-2 border border-blue-500 text-white rounded-xl flex gap-3 transition-all duration-300 w-full";

  return (
    <div className="fixed bottom-0 lg:static flex lg:flex-col w-screen lg:h-screen lg:w-1/6 px-5 py-2 bg-slate-700 items-center">
      <div className="grow lg:grow-0">
        <h2 className="text-xl text-white font-bold lg:mt-10">Mesh AR</h2>
      </div>

      <div className="flex lg:flex-col lg:mt-10 gap-5">
        <NavLink
          exact="true"
          to="/dashboard"
          className={({ isActive }) => (isActive ? activeLink : nonActiveLink)}
        >
          <MdDashboard className="text-2xl" />
          <p className="hidden md:block font-semibold">Dashboard</p>
        </NavLink>
        <NavLink
          to="/notification"
          className={({ isActive }) => (isActive ? activeLink : nonActiveLink)}
        >
          <MdNotifications className="text-white text-2xl" />
          <p className="hidden md:block font-semibold">Notifications</p>
        </NavLink>
      </div>
    </div>
  );
};

export default AppBar;
