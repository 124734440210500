import React, { useContext, useState } from "react";
import { MdEditNote } from "react-icons/md";
import Edit from "./Edit";

const Card = ({ product }) => {
  const [editVis, setEditVis] = useState(false);

  return (
    <>
      <div className="w-48 h-[270px] rounded-lg shadow bg-gradient-to-br from-black via-indigo-900 to-blue-400 border border-blue-800 overflow-hidden">
        <img
          className="aspect-square object-cover min-h-[190px] bg-white p-2"
          src={product?.poster}
          alt={product?.productName}
        />
        <div className="py-1 px-2 flex items-center justify-between text-white">
          <div>
            <p className="text-xl font-bold">{product?.productName}</p>
            <p className="font-bold leading-3">
              <span className="text-sm">{"₹ " + product?.price}</span>
              <br />
              <span className="text-xs">
                {product?.category
                  .replace(/-/g, " ")
                  .replace(/\b\w/g, (match) => match.toUpperCase())}
              </span>
            </p>
          </div>
          <div>
            <MdEditNote
              className="text-3xl cursor-pointer text-white sm:text-indigo-900 hover:text-white transition-all duration-300 hover:scale-125"
              onClick={(e) => {
                setEditVis(true);
              }}
            />
          </div>
        </div>
      </div>

      {editVis ? <Edit product={product} setEditVis={setEditVis} /> : ""}
    </>
  );
};

export default Card;
