import React, { useState } from "react";
import { auth } from "../firebase";
import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [details, setDetails] = useState(false);
  const [email, setEmail] = useState(false);
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    const password = e.target[1].value;

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            navigate("/dashboard");
          } else {
            alert("Something Went Wrong \nPlease Refill the form");
          }
        });
      })
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          setDetails(true);
        }
      });
  };

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Password Link Sent to your Email Address");
      })
      .catch((error) => {
        console.log(error);
        if (error) {
          alert("Please Fill Email");
        }
      });
  };

  return (
    <>
      <div className="w-full h-screen flex justify-center items-center">
        <div className="border border-blue-500 w-fit rounded-lg p-2 transition-all duration-300">
          <div className="block transition-all duration-300 hover:text-blue-500 tracking-wide text-gray-700 text-lg font-bold mb-3 border-b border-blue-500 cursor-pointer">
            Admin Login
          </div>
          <form className="w-96 max-w-lg" onSubmit={handleRegister}>
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="admin-email"
                >
                  E Mail
                  <span className="text-red-500">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="admin-email"
                  type="email"
                  placeholder="email@admin.com"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="admin-password"
                >
                  Password
                  <span className="text-red-500">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="password"
                  placeholder="********"
                  required
                />
              </div>
            </div>

            <div className="flex justify-center flex-wrap w-full mb-1">
              <p className="text-xs  text-red-500 font-bold">
                {details ? "Email / Password is Wrong" : ""}
              </p>
            </div>

            <div className="flex justify-center flex-wrap w-full mb-1 mt-6 ">
              <div className="w-1/2 px-1 md:px-3">
                <button
                  type="reset"
                  className="block w-full bg-gray-500 text-slate-200 text-center border rounded py-3 leading-tight focus:outline-none cursor-pointer"
                >
                  Reset
                </button>
              </div>
              <div className="w-1/2 px-1 md:px-3">
                <button
                  type="submit"
                  className={`block w-full text-center border rounded py-3 leading-tight focus:outline-none transition-all duration-300 font-bold ${"text-blue-700 border-blue-700 bg-blue-200 cursor-pointer"}`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
          <div className="flex justify-between items-center">
            <Link to="/register" className="item text-sm text-blue-800">
              Not Registered? <span className="underline">Click Here</span>
            </Link>
            <button
              className="item text-sm text-blue-800"
              onClick={handleResetPassword}
            >
              Forgot Password? <span className="underline">Click Here</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
