import React, { useEffect, useRef, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import AddNew from "../components/AddNew";
import { collection, onSnapshot, query } from "firebase/firestore";
import db from "../firebase";
import Card from "../components/Card";

const Products = () => {
  const addNewBtn = useRef();
  const [addVis, setAddVis] = useState(false);
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const q = query(collection(db, "products"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const productsData = [];

      querySnapshot.forEach((doc) => {
        productsData.push({ id: doc.id, ...doc.data() });
      });

      setProducts(productsData);
    });

    return unsubscribe;
  }, []);

  return (
    <div className="p-5">
      <h1 className="border-b border-blue-500 font-semibold text-md md:text-xl">
        Products
      </h1>

      {/* FILTER & ADD */}
      <div className="flex justify-between mx-4 my-2">
        {/* FILTER */}
        <div className="flex gap-2 items-center">
          <label className="font-bold text-md">Filter</label>
          <div className="relative">
            <select
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-1 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            >
              <option value="">All</option>
              <option value="designer-shelves">Designer Shelves</option>
              <option value="plant-stands">Plant Stands</option>
              <option value="gifts">Gifts</option>
              <option value="floor">Floor</option>
              <option value="wall">Wall</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
              <MdArrowDropDown className="text-xl" />
            </div>
          </div>
        </div>

        {/* ADD */}
        <button
          className="py-1 px-2 bg-blue-500 rounded-lg text-white font-bold text-sm sm:text-lg focus:outline-none"
          onClick={() => {
            setAddVis(true);
          }}
          ref={addNewBtn}
        >
          Add New
        </button>
      </div>

      <div className="my-3 mb-16 sm:mb-0">
        {products.length === 0 ? (
          <div className="flex flex-col justify-center items-center m-5">
            <img
              draggable="false"
              src="https://www.beevidhya.com/assets/images/no_result.gif"
              alt="No Files"
              className="rounded-xl mix-blend-multiply select-none"
            />
            <p className="mt-4 mb-1 text-lg font-semibold">
              Sorry! No results found :(
            </p>
            <button
              className="text-lg font-bold bg-blue-500 rounded-lg py-1 px-2 text-slate-100 tracking-tighter"
              onClick={() => {
                addNewBtn.current.click();
              }}
            >
              Add New Files to Show Products Here
            </button>
          </div>
        ) : (
          <div className="transition-all duration-300 flex flex-col items-center place-items-center sm:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
            {products
              .filter(
                (product) =>
                  filter === "" ||
                  product.category === filter ||
                  product.placement === filter
              )
              .map((product, i) => {
                return <Card key={i} product={product} />;
              })}
          </div>
        )}
      </div>

      {addVis ? <AddNew addVis={addVis} setAddVis={setAddVis} /> : null}
    </div>
  );
};

export default Products;
