import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import NotifyAdd from "../components/NotifyAdd";
import NotifyEdit from "../components/NotifyEdit";
import NotifyUser from "../components/NotifyUser";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import db, { auth } from "../firebase";
import { MdArrowDropDown } from "react-icons/md";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const q = query(collection(db, "notifications"), orderBy("on", "desc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notificationData = [];

      querySnapshot.forEach((doc) => {
        notificationData.push({ id: doc.id, ...doc.data() });
      });

      setNotifications(notificationData);
      console.log(notifications);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const authChange = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate("/login");
      }
    });

    return authChange;
  }, []);

  return (
    <div>
      <Header title="Notification" />
      <div className="m-3 md:m-8 bg-blue-500/30 rounded p-5">
        <div className="flex justify-between mx-4 my-2">
          {/* FILTER */}
          <div className="flex gap-2 items-center">
            <label className="font-bold text-md">Filter</label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-1 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e) => {
                  e.preventDefault();
                  setFilter(e.target.value);
                }}
              >
                <option value="">All</option>
                <option value="product add">New Products</option>
                <option value="product edit">Edited Products</option>
                <option value="new user">New User</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
                <MdArrowDropDown className="text-xl" />
              </div>
            </div>
          </div>
        </div>

        <div>
          {notifications
            ? notifications
                .filter((product) => filter === "" || product.type === filter)
                .map((notification, key) => {
                  if (notification?.type === "new user") {
                    return <NotifyUser key={key} notification={notification} />;
                  }

                  if (notification?.type === "product add") {
                    return <NotifyAdd key={key} notification={notification} />;
                  }

                  if (notification?.type === "product edit") {
                    return <NotifyEdit key={key} notification={notification} />;
                  }
                })
            : "No Notifications"}
        </div>
      </div>
      <div className="h-20"></div>
    </div>
  );
};

export default Notification;
