import React, { useEffect, useState } from "react";
import {
  MdArrowDropDown,
  MdOutlineAddCircle,
  MdCheckCircle,
  MdDelete,
} from "react-icons/md";
import { BiLoaderCircle } from "react-icons/bi";

import db, { auth, storage } from "../firebase";
import {
  doc,
  serverTimestamp,
  deleteDoc,
  updateDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

const EditForm = ({
  setEditVis,
  product,
  setGlbFilePrev,
  setGlbWOFilePrev,
}) => {
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("designer-shelves");
  const [placement, setPlacement] = useState("floor");
  const [price, setPrice] = useState(0);
  const [about, setAbout] = useState("");
  const [weight, setWeight] = useState("");
  const [load, setLoad] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [link, setLink] = useState("");

  const [posterFile, setPosterFile] = useState(null);
  const [glbFile, setGLbFile] = useState(null);
  const [glbWOFile, setGLbWOFile] = useState(null);
  const [posterSrc, setPosterSrc] = useState(null);

  const [fileUrls, setFileUrls] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [formSubmitLoad, setFormSubmitLoad] = useState(false);

  const [isHover, setIsHover] = useState(false);

  // COLOR
  const [colors, setColors] = useState(product ? product.color : ["#f1ece1"]);
  const [colorSwatchVis, setColorSwatchVis] = useState(false);
  const [currentColor, setCurrentColor] = useState("");
  const [currentColorIndex, setCurrentColorIndex] = useState(null);

  const deleteColor = (index) => {
    const newColors = [...colors];
    newColors.splice(index, 1);
    setColors(newColors);
  };

  const updateColor = (index, newColor) => {
    const newColors = [...colors];
    newColors[index] = newColor;
    setColors(newColors);
  };

  const handleColorClick = (index) => {
    setCurrentColorIndex(index);
    setCurrentColor(colors[index]);
    setColorSwatchVis(true);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
        console.log(error);
      };
    });
  };

  const updateDocument = async () => {
    let editLogs = [];
    const storageRef = doc(db, "products", product?.productName);

    if (productName !== "") {
      await updateDoc(storageRef, {
        productName: productName,
      }).then((e) => {
        editLogs.push("Product Name");
        console.log("productName Updated\n", e);
      });
    }

    if (category !== product.category) {
      await updateDoc(storageRef, {
        category: category,
      }).then((e) => {
        editLogs.push("Category");
        console.log("category Updated\n", e);
      });
    }

    if (placement !== product.placement) {
      await updateDoc(storageRef, {
        placement: placement,
      }).then((e) => {
        editLogs.push("Placement");
        console.log("placement Updated\n", e);
      });
    }

    if (fileUrls[0]) {
      await updateDoc(storageRef, {
        poster: fileUrls[0],
      }).then((e) => {
        editLogs.push("Poster");
        console.log("poster Updated\n", e);
      });
    }

    if (fileUrls[1]) {
      await updateDoc(storageRef, {
        glbUrl: fileUrls[1],
      }).then((e) => {
        editLogs.push("Glb File");
        console.log("glbUrl Updated\n", e);
      });
    }

    if (fileUrls[2]) {
      await updateDoc(storageRef, {
        glbWOUrl: fileUrls[2],
      }).then((e) => {
        editLogs.push("Glb With Object File");
        console.log("glbWOUrl Updated\n", e);
      });
    }

    if (price !== 0 && price !== product.price) {
      await updateDoc(storageRef, {
        price: price,
      }).then((e) => {
        editLogs.push("Price");
        console.log("price Updated\n", e);
      });
    }

    if (about !== "") {
      await updateDoc(storageRef, {
        about: about,
      }).then((e) => {
        setEdited([...edited, "About"]);
        console.log("about Updated\n", e);
      });
    }

    if (weight !== "") {
      await updateDoc(storageRef, {
        weight: weight,
      }).then((e) => {
        editLogs.push("Weight");
        console.log("weight Updated\n", e);
      });
    }

    if (load !== "") {
      await updateDoc(storageRef, {
        load: load,
      }).then((e) => {
        editLogs.push("Load Carry Capacity");
        console.log("load Updated\n", e);
      });
    }

    if (dimensions !== "") {
      await updateDoc(storageRef, {
        dimensions: dimensions,
      }).then((e) => {
        editLogs.push("Dimensions");
        console.log("dimensions Updated\n", e);
      });
    }

    await updateDoc(storageRef, {
      color: colors,
      lastUpdatedOn: serverTimestamp(),
      lastUpdatedBy: auth.currentUser.email,
    });

    console.log(editLogs);
    await addDoc(collection(db, "notifications"), {
      type: "product edit",
      productName: productName === "" ? product?.productName : productName,
      on: serverTimestamp(),
      edits: editLogs,
      by: auth.currentUser.email,
    });

    setTimeout(() => {
      setEditVis(false);
      alert(`Meshable's ${productName} Edited`);
    }, 2000);

    console.log("submitted");
  };

  const uploadFiles = async (files) => {
    const downloadUrls = [];

    try {
      await Promise.all(
        files.map(async (file) => {
          const storageRef = ref(storage, `${productName}/${file.name}`);
          const snapshot = await uploadBytesResumable(storageRef, file);

          const downloadURL = await getDownloadURL(snapshot.ref);
          downloadUrls.push(downloadURL);
        })
      );

      console.log("All files uploaded:", downloadUrls);
      return downloadUrls;
    } catch (error) {
      console.log("Error uploading files:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitLoad(true);
    console.log(
      productName,
      category,
      placement,
      posterFile,
      glbFile,
      glbWOFile,
      price,
      about,
      weight,
      load,
      dimensions,
      link
    );

    const downloadURLs = [];

    const uploadFileAndGetURL = async (file, index) => {
      if (file) {
        const files = [file];
        downloadURLs[index] = await uploadFiles(files);
      }
    };

    await Promise.all([
      uploadFileAndGetURL(posterFile, 0),
      uploadFileAndGetURL(glbFile, 1),
      uploadFileAndGetURL(glbWOFile, 2),
    ]);

    setFileUrls(downloadURLs);
  };

  const handleFileDelete = async (link) => {
    const decodedLink = decodeURIComponent(link);

    // Extract the file name using regex
    const pattern = /\/([^/?]+)\?/;
    const match = decodedLink.match(pattern);
    const fileName = match ? match[1] : null;

    console.log("fileName", fileName);
    console.log(`${product?.productName}/${fileName}`);

    const deleteRef = ref(storage, `${product?.productName}/${fileName}`);

    // Delete the file
    await deleteObject(deleteRef)
      .then(() => {
        console.log("file deleted");
      })
      .catch((error) => {
        console.log(
          `Error occured in deleting ${product?.productName}:\n`,
          error
        );
      });
  };

  const checkIfDeleted = async () => {
    if (product?.poster !== "") {
      await handleFileDelete(product?.poster);
    }

    if (product?.glbUrl !== "") {
      await handleFileDelete(product?.glbUrl);
    }

    if (product?.glbWOUrl !== "") {
      await handleFileDelete(product?.glbWOUrl);
    }

    return true;
  };

  const handleDelete = async () => {
    const userConfirmed = window.confirm("Are you sure you want to proceed?");

    if (userConfirmed) {
      const deletedFiles = await checkIfDeleted();
      if (product?.productName && deletedFiles) {
        await deleteDoc(doc(db, "products", product.productName));

        alert(
          `The Document ${product?.productName} has been successfully deleted`
        );
      }
    }
  };

  useEffect(() => {
    console.log(fileUrls);
    if (formSubmitLoad) {
      updateDocument();
    }
  }, [formSubmitLoad]);

  useEffect(() => {
    document.title = `Mesh AR | ${
      productName !== "" ? productName : product?.productName
    }`;
  }, []);

  return (
    <div className="m-5">
      <form
        className="w-full"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="overflow-scroll overflow-x-hidden h-[350px] md:h-[450px]">
          {/* FORM 1 */}
          {/* BASIC DETAILS */}
          <div className={`mb-4`}>
            <p className="text-lg md:text-xl font-bold mb-4">Basic Details:</p>
            <div className="flex flex-wrap  mb-2">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-slate-700 text-xs font-bold mb-2"
                  htmlFor="product-name"
                >
                  Product Name <span className="text-red-600">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="product-name"
                  type="text"
                  placeholder="Marmelos - 3T"
                  defaultValue={product?.productName}
                  onChange={(e) => {
                    setProductName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-wrap  mb-4">
              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="category"
                >
                  Category <span className="text-red-600">*</span>
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="category"
                    defaultValue={product?.category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <option value="designer-shelves">Designer Shelves</option>
                    <option value="plant-organizer">Plant Organizer</option>
                    <option value="gifts">Gifts</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <MdArrowDropDown className="text-xl" />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="placement"
                >
                  Placement <span className="text-red-600">*</span>
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="placement"
                    defaultValue={product?.placement}
                    onChange={(e) => {
                      setPlacement(e.target.value);
                    }}
                  >
                    <option value="floor">Floor</option>
                    <option value="wall">Wall</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <MdArrowDropDown className="text-xl" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mb-1 relative">
              <div
                className="w-full px-3 mb-1"
                onMouseOver={(e) => {
                  setIsHover(true);
                }}
                onMouseOut={(e) => {
                  setIsHover(false);
                }}
              >
                <label
                  className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
                    product?.poster
                      ? "animate-pulse text-green-700"
                      : "text-gray-700"
                  }`}
                  htmlFor="poster"
                >
                  <a href={product?.poster} target="_blank">
                    Poster
                  </a>
                  <span className="text-red-500">*</span>
                </label>

                <div className="flex">
                  <input
                    className="grow appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 file:py-1 file:px-2 file:cursor-pointer file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 cursor-pointer"
                    id="poster"
                    type="file"
                    accept=".png, .jpg, .jpeg, .webp"
                    title={" "}
                    onChange={async (e) => {
                      var file = e.target.files[0];
                      if (file) {
                        setPosterFile(file);
                        const base64 = await convertBase64(file);
                        setPosterSrc(base64);
                      }
                    }}
                  />
                </div>
              </div>

              {posterSrc || product?.poster ? (
                <div
                  className={`${
                    isHover ? "scale-100" : "scale-0"
                  } absolute bottom-14 duration-300 transition-all left-1/2 -translate-x-1/2 flex flex-col items-center z-50`}
                >
                  <img
                    src={posterSrc ? posterSrc : product?.poster}
                    className="h-36 w-full"
                    alt="poster"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex justify-center items-center my-6">
            <div className="w-11/12 h-1 bg-slate-300 rounded-full"></div>
          </div>

          {/* FORM 2 */}
          {/* MODEL FILES */}
          <div className={`mb-9`}>
            <p className="text-lg md:text-xl font-bold mb-4">Model Files:</p>
            <div className="w-full px-3 mb-6 md:mb-0">
              <label
                className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
                  product?.glbUrl
                    ? "animate-pulse text-green-700"
                    : "text-gray-700"
                }`}
                htmlFor="glb-file"
              >
                GLB File <span className="text-red-500">*</span>
              </label>
              <div className="flex">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white file:py-1 file:px-2 file:cursor-pointer file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700"
                  id="glb-file"
                  type="file"
                  accept=".glb,/glb"
                  onChange={async (e) => {
                    var file = e.target.files[0];
                    if (file) {
                      setGLbFile(file);
                      const base64 = await convertBase64(file);
                      setGlbFilePrev(base64);
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-full px-3 mb-6">
              <label
                className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
                  product?.glbWOUrl
                    ? "animate-pulse text-green-700"
                    : "text-gray-700"
                }`}
                htmlFor="glb-with-object"
              >
                Glb With Object
              </label>
              <div className="flex">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 file:py-1 file:px-2 file:cursor-pointer file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700"
                  id="glb-with-object"
                  type="file"
                  accept=".glb,/glb"
                  onChange={async (e) => {
                    var file = e.target.files[0];
                    if (file) {
                      setGLbWOFile(file);
                      const base64 = await convertBase64(file);
                      setGlbWOFilePrev(base64);
                    }
                  }}
                />
              </div>
            </div>

            <div className="w-full px-3 mb-1">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="colors"
              >
                Colors <span className="text-red-500">*</span>
              </label>
              {/* Color Swatches */}
              <div className="w-full px-3 mb-1">
                <div className="grid grid-cols-3 lg:grid-cols-6 gap-1">
                  {colors.map((c, i) => {
                    return (
                      <div key={i} className="relative cursor-pointer">
                        <div
                          className="w-10 h-6 border border-slate-700 rounded-sm"
                          style={{
                            backgroundColor: c,
                          }}
                          onClick={() => handleColorClick(i)}
                        />
                        {colorSwatchVis === true && currentColorIndex === i ? (
                          <div className="abolute top-0 mt-1 left-0 border border-slate-700 bg-white w-32 p-1 rounded-md">
                            <input
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="hex-code"
                              type="text"
                              placeholder="#00000"
                              value={currentColor}
                              onChange={(e) => setCurrentColor(e.target.value)}
                            />
                            <div className="flex gap-1 items-center justify-center">
                              <MdCheckCircle
                                className="text-2xl border border-green-800 bg-green-400 text-white w-full rounded cursor-pointer"
                                type="button"
                                onClick={() => {
                                  updateColor(currentColorIndex, currentColor);
                                  setColorSwatchVis(false);
                                }}
                              />
                              <MdDelete
                                className="text-2xl border border-red-800 bg-red-500 text-white w-full rounded cursor-pointer"
                                type="button"
                                onClick={() => {
                                  deleteColor(currentColorIndex);
                                  setColorSwatchVis(false);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                  <div className="justify-self-start self-center">
                    <MdOutlineAddCircle
                      className="text-2xl text-blue-500 cursor-pointer"
                      onClick={() => {
                        setColors(colors.concat("#f1ece1"));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center my-6">
            <div className="w-11/12 h-1 bg-slate-300 rounded-full"></div>
          </div>

          {/* FORM 3 */}
          {/* ADDITIONAL DETAILS */}
          <div className={`mb-4`}>
            <p className="text-lg md:text-xl font-bold mb-4">
              Additional Details:
            </p>
            <div className="flex flex-wrap  mb-1">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="price"
                >
                  Price <span className="text-red-600">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="price"
                  type="number"
                  placeholder="1080"
                  defaultValue={product?.price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="about"
                >
                  About
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="about"
                  type="text"
                  defaultValue={product?.about}
                  placeholder="A Three Tier Marmelos Plant Stand"
                  onChange={(e) => {
                    setAbout(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-wrap  mb-1">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="weight"
                >
                  Weight
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="weight"
                  type="text"
                  defaultValue={product?.weight}
                  placeholder="2 Kg"
                  onChange={(e) => {
                    setWeight(e.target.value);
                  }}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-2">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="load-capacity"
                >
                  Load Carrying Capacity
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="load-capacity"
                  type="text"
                  defaultValue={product?.load}
                  placeholder="10 kg"
                  onChange={(e) => {
                    setLoad(e.target.value);
                  }}
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="dimensions"
                >
                  Dimensions
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="dimensions"
                  type="text"
                  defaultValue={product?.dimensions}
                  placeholder="68.5 × 30 × 15 cm"
                  onChange={(e) => {
                    setDimensions(e.target.value);
                  }}
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="dimensions"
                >
                  Link to Site
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="link"
                  defaultValue={product?.link}
                  type="text"
                  placeholder="https://meshable.in/shop/plant-stands/marmelos-3t-metal-plant-stand/"
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="my-5 text-xs font-bold">
            <p>Extra Info</p>
            <div className="my-2">
              <p>Last Updated By:</p>
              <p>
                <span>{`\n\t${product?.lastUpdatedBy}`}</span>
                <span>{`\n\t${product?.lastUpdatedOn
                  ?.toDate()
                  ?.toLocaleString()}`}</span>
              </p>
            </div>
            <div className="my-2">
              <p>Created By:</p>
              <p>
                <span>{`\n\t${product?.createBy}`}</span>
                <span>{`\n\t${product?.createOn
                  ?.toDate()
                  ?.toLocaleString()}`}</span>
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-center flex-wrap w-full ">
          <div className="w-1/2 px-1 md:px-3">
            <button
              className="block w-full bg-red-500 text-slate-200 text-center border rounded py-3 leading-tight focus:outline-none cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                handleDelete();
              }}
            >
              Delete
            </button>
          </div>
          <div className="w-1/2 px-1 md:px-3">
            <button
              type="submit"
              className={`block w-full text-center border rounded py-3 leading-tight focus:outline-none transition-all duration-300 font-bold ${"text-blue-700 border-blue-700 bg-blue-200 cursor-pointer"}`}
            >
              <p
                className={
                  formSubmitLoad
                    ? "animate-spin flex justify-center items-center"
                    : "animate-none flex justify-center items-center"
                }
              >
                {formSubmitLoad ? <BiLoaderCircle /> : "Submit"}
              </p>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditForm;
