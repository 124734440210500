import { signOut } from "firebase/auth";
import React from "react";
import { MdLogout } from "react-icons/md";
import { auth } from "../firebase";

const Header = ({ title, userMail }) => {
  const handleLogOut = async () => {
    await signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-full h-12 px-5 py-2 border-b border-blue-500 flex items-center">
      <h1 className="text-lg lg:text-xl font-bold grow">{title}</h1>
      <p className="text-xs sm:text-sm ">{userMail ? userMail : ""}</p>
      <MdLogout
        className="p-[2px] text-2xl ml-2 transition-all duration-300 cursor-pointer hover:scale-110 rounded-full border hover:border-slate-700 hover:text-blue-500"
        onClick={handleLogOut}
      />
    </div>
  );
};

export default Header;
