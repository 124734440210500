import React from "react";
import { Link } from "react-router-dom";

function Verify() {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="border border-blue-700 bg-blue-300 rounded-xl overflow-hidden">
        <div className="px-6 py-3 border-b border-blue-700 font-bold">
          <p>
            Please
            <span className="animate-pulse text-red-600"> Verify </span>
            Your Mail To Access The ADMIN | Mesh AR
          </p>
        </div>
        <div className="px-4 py-1 bg-blue-100 flex justify-end">
          <p className="font-bold text-green-800">
            If Verified ?{" "}
            <Link to="/login" className="underline text-blue-800 font-medium">
              Click here to Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Verify;
