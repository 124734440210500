import React from "react";
import { TiUserAdd } from "react-icons/ti";

function NotifyUser({ notification }) {
  return (
    <div className="m-2 rounded-lg border-2 border-green-500 bg-green-200">
      <div className="flex items-center gap-2 px-3 py-1 border-b-2 border-green-500">
        <TiUserAdd className="text-green-500 text-lg" />
        <p className="text-sm font-bold text-green-500">New User Added</p>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center gap-2 px-3 py-3">
        <p className="text-base font-semibold">
          Added user {notification?.email} to system
        </p>
        <p className="text-[10px] font-mono">
          {notification?.on?.toDate().toString()}
        </p>
      </div>
    </div>
  );
}

export default NotifyUser;
