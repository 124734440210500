import React, { useEffect } from "react";
import AppBar from "../components/AppBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Dashboard from "./Dashboard";
import Notification from "./Notification";
import Login from "./Login";
import Register from "./Register";

import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Verify from "./Verify";

const MainScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const authChange = onAuthStateChanged(auth, async (user) => {
      if (user) {
        navigate("/dashboard");
      }
    });

    return authChange;
  }, []);

  return (
    <div className="flex w-screen h-screen overflow-hidden">
      {location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/verify" ? (
        <></>
      ) : (
        <AppBar />
      )}
      <div className="w-full h-full overflow-scroll">
        <Routes>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="notification" element={<Notification />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="verify" element={<Verify />} />
        </Routes>
      </div>
    </div>
  );
};

export default MainScreen;
