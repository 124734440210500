import React from "react";
import { RiEditCircleFill } from "react-icons/ri";

function NotifyEdit({ notification }) {
  console.log("in edit", notification);

  return (
    <div className="m-2 rounded-lg border-2 border-violet-500 bg-violet-200">
      <div className="flex items-center gap-2 px-3 py-1 border-b-2 border-violet-500">
        <RiEditCircleFill className="text-violet-500 text-lg" />
        <p className="text-sm font-bold text-violet-500">File Edited</p>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center gap-2 px-3 pt-2">
        <p className="text-base">
          <span>File </span>
          <span className="font-semibold underline">
            {notification.productName}
          </span>
          <span> was edited by </span>
          <span className="font-semibold underline">{notification?.by}</span>
        </p>
        <p className="text-[10px] font-mono md:text-right md:w-5/12">
          {notification?.on?.toDate()?.toString()}
        </p>
      </div>
      <p className="px-3 pb-1 text-xs">
        {notification?.edits?.map((edit, index) => {
          // Check if it's the last item in the array
          const isLastItem = index === notification.edits.length - 1;

          // Append a comma (,) if it's not the last item
          const comma = isLastItem ? " " : ", ";

          // Return the edited item with a comma
          return (
            <span key={index} className="font-bold">
              {edit}
              {comma}
            </span>
          );
        })}
        was edited
      </p>
    </div>
  );
}

export default NotifyEdit;
