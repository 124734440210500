import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import AddNewForm from "./AddNewForm";

const AddNew = ({ setAddVis }) => {
  const [animate, setAnimate] = useState("animate-scale-up-center");
  const [glbFilePrev, setGlbFilePrev] = useState(
    "https://firebasestorage.googleapis.com/v0/b/mesh-ar.appspot.com/o/Other%20Files%2FFilePreview.glb?alt=media&token=1a850923-a267-4bba-b88a-d88d5bc64ab9&_gl=1*79t7l7*_ga*ODQyMDc2NzE4LjE2NzU5MzQzMzI.*_ga_CW55HF8NVT*MTY4NTQyNDQ3MC40Ny4xLjE2ODU0MjQ1NDcuMC4wLjA."
  );
  const [glbWOFilePrev, setGlbWOFilePrev] = useState(
    "https://firebasestorage.googleapis.com/v0/b/mesh-ar.appspot.com/o/Other%20Files%2FFilePreviewOB.glb?alt=media&token=8b6ee294-c8cd-43b7-8b2b-8e5b8070a66c&_gl=1*1d4k6mj*_ga*ODQyMDc2NzE4LjE2NzU5MzQzMzI.*_ga_CW55HF8NVT*MTY4NTQyNDQ3MC40Ny4xLjE2ODU0MjQ1NjYuMC4wLjA."
  );

  return (
    <div className={`absolute top-0 left-0 z-50 ${animate}`}>
      <div
        className="w-screen h-screen top-0 bg-blue-950/40"
        onClick={() => {
          setAddVis(false);
        }}
      ></div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-4/6 h-5/6 rounded-xl shadow-[rgba(0,_0,_0,_0.2)_0px_60px_40px_-7px]">
        <div className="flex flex-col md:flex-row h-full w-full">
          {/* LEFT */}
          <div className="h-[700px] md:h-full w-full border-r border-black flex flex-col md:flex-col lg:flex">
            {/* TOP */}
            <div className="w-full h-full">
              <model-viewer
                style={{ width: "100%", height: "100%" }}
                alt="Preview"
                src={glbFilePrev}
                shadow-intensity="1"
                camera-controls
                touch-action="pan-y"
              />
            </div>

            {/* BOTTOM */}
            <div className="w-full h-full">
              <model-viewer
                style={{ width: "100%", height: "100%" }}
                alt="Preview"
                src={glbWOFilePrev}
                shadow-intensity="1"
                camera-controls
                touch-action="pan-y"
              />
            </div>
          </div>

          {/* RIGHT */}
          <div className="h-full w-full">
            <div className="flex justify-end my-2 mx-3">
              <button
                className="border border-black rounded-full hover:scale-125 cursor-pointer hover:border-red-500 hover:text-red-500 transition-all duration-300"
                title="Close Form"
                onClick={() => {
                  setAddVis(false);
                }}
              >
                <MdClose />
              </button>
            </div>
            <AddNewForm
              setAddVis={setAddVis}
              setGlbFilePrev={setGlbFilePrev}
              setGlbWOFilePrev={setGlbWOFilePrev}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNew;
