import React, { useEffect, useState } from "react";
import {
  MdArrowDropDown,
  MdOutlineAddCircle,
  MdCheckCircle,
  MdDelete,
} from "react-icons/md";
import { BiLoaderCircle } from "react-icons/bi";

import db, { auth, storage } from "../firebase";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import { v4 as uuid } from "uuid";

const AddNewForm = ({ setAddVis, setGlbFilePrev, setGlbWOFilePrev }) => {
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("designer-shelves");
  const [placement, setPlacement] = useState("floor");
  const [price, setPrice] = useState(0);
  const [about, setAbout] = useState("");
  const [weight, setWeight] = useState("");
  const [load, setLoad] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [link, setLink] = useState("");

  const [posterFile, setPosterFile] = useState(null);
  const [glbFile, setGLbFile] = useState(null);
  const [glbWOFile, setGLbWOFile] = useState(null);
  const [posterSrc, setPosterSrc] = useState(null);

  const [fileUrls, setFileUrls] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [formSubmitLoad, setFormSubmitLoad] = useState(false);

  const [isHover, setIsHover] = useState(false);

  const [colors, setColors] = useState(["#f1ece1"]);
  const [colorSwatchVis, setColorSwatchVis] = useState(false);
  const [currentColor, setCurrentColor] = useState("");
  const [currentColorIndex, setCurrentColorIndex] = useState(null);

  const deleteColor = (index) => {
    const newColors = [...colors];
    newColors.splice(index, 1);
    setColors(newColors);
  };

  const updateColor = (index, newColor) => {
    const newColors = [...colors];
    newColors[index] = newColor;
    setColors(newColors);
  };

  const handleColorClick = (index) => {
    setCurrentColorIndex(index);
    setCurrentColor(colors[index]);
    setColorSwatchVis(true);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
        console.log(error);
      };
    });
  };

  const setDocument = async () => {
    await setDoc(doc(db, "products", productName), {
      id: productName.replace(/\s+/g, "") + uuid().slice(0, 8),
      productName: productName,
      category: category,
      placement: placement,
      poster: fileUrls[0],
      glbUrl: fileUrls[1],
      glbWOUrl: fileUrls[2],
      color: colors,
      price: price,
      about: about,
      weight: weight,
      load: load,
      dimensions: dimensions,
      link: link,
      lastUpdatedOn: serverTimestamp(),
      lastUpdatedBy: auth.currentUser.email,
      createOn: serverTimestamp(),
      createBy: auth.currentUser.email,
    }).then(async () => {
      await addDoc(collection(db, "notifications"), {
        type: "product add",
        productName: productName,
        category: category,
        price: price,
        on: serverTimestamp(),
        by: auth.currentUser.email,
      });

      setTimeout(() => {
        setAddVis(false);
        alert(`Meshable's ${productName} Added`);
      }, 2000);

      console.log("submitted");
    });
  };

  const uploadFiles = async (files) => {
    try {
      const downloadUrls = []; // Object to store download URLs

      const uploadTasks = files.map((file) => {
        const storageRef = ref(storage, `${productName}/` + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Upload progress handling
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            (error) => {
              // Error handling
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              downloadUrls.push(downloadURL); // Store the download URL in the object
              resolve();
            }
          );
        });
      });

      // Wait for all upload tasks to complete
      await Promise.all(uploadTasks);

      console.log("All files uploaded:", downloadUrls);
      return downloadUrls;
    } catch (error) {
      console.log("Error uploading files:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitLoad(true);
    console.log(
      productName,
      category,
      placement,
      posterFile,
      glbFile,
      glbWOFile,
      price,
      about,
      weight,
      load,
      dimensions,
      link
    );

    if (glbWOFile) {
      const files = [posterFile, glbFile, glbWOFile];
      const downloadURLs = await uploadFiles(files);
      setFileUrls(downloadURLs);
    } else {
      const files = [posterFile, glbFile];
      const downloadURLs = await uploadFiles(files);
      downloadURLs.push("");
      setFileUrls(downloadURLs);
    }
  };

  useEffect(() => {
    console.log(fileUrls);
    if (formSubmitLoad & (fileUrls.length > 0)) {
      setDocument();
    }
  }, [fileUrls, formSubmitLoad]);

  useEffect(() => {
    document.title = `Mesh AR | ${productName}`;
  }, []);

  return (
    <div className="m-5">
      <form
        className="w-full"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="overflow-scroll overflow-x-hidden h-[250px] md:h-[450px]">
          {/* FORM 1 */}
          {/* BASIC DETAILS */}
          <div className={`mb-4`}>
            <p className="text-lg md:text-xl font-bold mb-4">Basic Details:</p>
            <div className="flex flex-wrap  mb-2">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-slate-700 text-xs font-bold mb-2"
                  htmlFor="product-name"
                >
                  Product Name <span className="text-red-600">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="product-name"
                  type="text"
                  placeholder="Marmelos - 3T"
                  required
                  onChange={(e) => {
                    setProductName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-wrap  mb-4">
              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="category"
                >
                  Category <span className="text-red-600">*</span>
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="category"
                    required
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <option value="designer-shelves">Designer Shelves</option>
                    <option value="plant-organizer">Plant Organizer</option>
                    <option value="gifts">Gifts</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <MdArrowDropDown className="text-xl" />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="placement"
                >
                  Placement <span className="text-red-600">*</span>
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="placement"
                    required
                    onChange={(e) => {
                      setPlacement(e.target.value);
                    }}
                  >
                    <option value="floor">Floor</option>
                    <option value="wall">Wall</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <MdArrowDropDown className="text-xl" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mb-1 relative">
              <div
                className="w-full px-3 mb-1"
                onMouseOver={(e) => {
                  setIsHover(true);
                }}
                onMouseOut={(e) => {
                  setIsHover(false);
                }}
              >
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="poster"
                >
                  Poster <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <input
                    className="grow appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 file:py-1 file:px-2 file:cursor-pointer file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 cursor-pointer"
                    id="poster"
                    type="file"
                    accept=".png, .jpg, .jpeg, .webp"
                    required
                    title={" "}
                    onChange={async (e) => {
                      var file = e.target.files[0];
                      if (file) {
                        setPosterFile(file);
                        const base64 = await convertBase64(file);
                        setPosterSrc(base64);
                      }
                    }}
                  />
                </div>
              </div>

              {posterSrc ? (
                <div
                  className={`${
                    isHover ? "scale-100" : "scale-0"
                  } absolute bottom-20 duration-300 transition-all left-1/2 -translate-x-1/2 flex flex-col items-center z-50`}
                >
                  <img
                    src={posterSrc}
                    className="h-36 w-full bg-white p-1"
                    alt="poster"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex justify-center items-center my-6">
            <div className="w-11/12 h-1 bg-slate-300 rounded-full"></div>
          </div>

          {/* FORM 2 */}
          {/* MODEL FILES */}
          <div className={`mb-9`}>
            <p className="text-lg md:text-xl font-bold mb-4">Model Files:</p>
            <div className="w-full px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="glb-file"
              >
                GLB File <span className="text-red-500">*</span>
              </label>
              <div className="flex">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white file:py-1 file:px-2 file:cursor-pointer file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700"
                  id="glb-file"
                  type="file"
                  accept=".glb,/glb"
                  required
                  onChange={async (e) => {
                    var file = e.target.files[0];
                    if (file) {
                      setGLbFile(file);
                      const base64 = await convertBase64(file);
                      setGlbFilePrev(base64);
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-full px-3 mb-6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="glb-with-object"
              >
                Glb With Object
              </label>
              <div className="flex">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 file:py-1 file:px-2 file:cursor-pointer file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700"
                  id="glb-with-object"
                  type="file"
                  accept=".glb,/glb"
                  onChange={async (e) => {
                    var file = e.target.files[0];
                    if (file) {
                      setGLbWOFile(file);
                      const base64 = await convertBase64(file);
                      setGlbWOFilePrev(base64);
                    }
                  }}
                />
              </div>
            </div>

            <div className="w-full px-3 mb-1">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="colors"
              >
                Colors <span className="text-red-500">*</span>
              </label>
              {/* Color Swatches */}
              <div className="w-full px-3 mb-1">
                <div className="grid grid-cols-3 lg:grid-cols-6 gap-1">
                  {colors.map((c, i) => {
                    return (
                      <div key={i} className="relative cursor-pointer">
                        <div
                          className="w-10 h-6 border border-slate-700 rounded-sm"
                          style={{
                            backgroundColor: c,
                          }}
                          onClick={() => handleColorClick(i)}
                        />
                        {colorSwatchVis === true && currentColorIndex === i ? (
                          <div className="abolute top-0 mt-1 left-0 border border-slate-700 bg-white w-32 p-1 rounded-md">
                            <input
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="hex-code"
                              type="text"
                              placeholder="#00000"
                              value={currentColor}
                              onChange={(e) => setCurrentColor(e.target.value)}
                            />
                            <div className="flex gap-1 items-center justify-center">
                              <MdCheckCircle
                                className="text-2xl border border-green-800 bg-green-400 text-white w-full rounded cursor-pointer"
                                type="button"
                                onClick={() => {
                                  updateColor(currentColorIndex, currentColor);
                                  setColorSwatchVis(false);
                                }}
                              />
                              <MdDelete
                                className="text-2xl border border-red-800 bg-red-500 text-white w-full rounded cursor-pointer"
                                type="button"
                                onClick={() => {
                                  deleteColor(currentColorIndex);
                                  setColorSwatchVis(false);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                  <div className="justify-self-start self-center">
                    <MdOutlineAddCircle
                      className="text-2xl text-blue-500 cursor-pointer"
                      onClick={() => {
                        setColors(colors.concat("#f1ece1"));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center my-6">
            <div className="w-11/12 h-1 bg-slate-300 rounded-full"></div>
          </div>

          {/* FORM 3 */}
          {/* ADDITIONAL DETAILS */}
          <div className={`mb-4`}>
            <p className="text-lg md:text-xl font-bold mb-4">
              Additional Details:
            </p>
            <div className="flex flex-wrap  mb-1">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="price"
                >
                  Price <span className="text-red-600">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="price"
                  type="number"
                  placeholder="1080"
                  required
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="about"
                >
                  About
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="about"
                  type="text"
                  placeholder="A Three Tier Marmelos Plant Stand"
                  onChange={(e) => {
                    setAbout(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-wrap  mb-1">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="weight"
                >
                  Weight
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="weight"
                  type="text"
                  placeholder="2 Kg"
                  onChange={(e) => {
                    setWeight(e.target.value);
                  }}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-2">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="load-capacity"
                >
                  Load Carrying Capacity
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="load-capacity"
                  type="text"
                  placeholder="10 kg"
                  onChange={(e) => {
                    setLoad(e.target.value);
                  }}
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="dimensions"
                >
                  Dimensions
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="dimensions"
                  type="text"
                  placeholder="68.5 × 30 × 15 cm"
                  onChange={(e) => {
                    setDimensions(e.target.value);
                  }}
                />
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="dimensions"
                >
                  Link to Site
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="link"
                  type="text"
                  placeholder="https://meshable.in/shop/plant-stands/marmelos-3t-metal-plant-stand/"
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center flex-wrap w-full ">
          <div className="w-1/2 px-1 md:px-3">
            <button
              className="block w-full bg-gray-500 text-slate-200 text-center border rounded py-3 leading-tight focus:outline-none cursor-pointer"
              disabled={formSubmitLoad}
              onClick={(e) => {
                e.preventDefault();
                setAddVis(false);
              }}
            >
              Close
            </button>
          </div>
          <div className="w-1/2 px-1 md:px-3">
            <button
              type="submit"
              className={`block w-full text-center border rounded py-3 leading-tight focus:outline-none transition-all duration-300 font-bold ${"text-blue-700 border-blue-700 bg-blue-200 cursor-pointer"}`}
              disabled={formSubmitLoad}
            >
              <p
                className={
                  formSubmitLoad
                    ? "animate-spin flex justify-center items-center"
                    : "animate-none flex justify-center items-center"
                }
              >
                {formSubmitLoad ? <BiLoaderCircle /> : "Submit"}
              </p>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewForm;
