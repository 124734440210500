import React, { useState } from "react";
import db, { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const Register = () => {
  const [emailExist, setEmailExist] = useState(false);

  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();

    const email = e.target[0].value;
    const password = e.target[1].value;

    // Check if email address contains the required domain
    const emailRegex = /^[a-zA-Z0-9._%+-]+@a1fenceproducts\.com$/i;

    if (!emailRegex.test(email)) {
      alert(
        "Registration is only allowed with @a1fenceproducts.com email addresses"
      );
      return;
    }

    // Check password strength
    var strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!strongRegex.test(password)) {
      alert(
        "Password must be at least 8 characters long and contain at least one letter and one digit."
      );
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        if (userCredential) {
          await sendEmailVerification(auth.currentUser).then(async () => {
            await addDoc(collection(db, "notifications"), {
              type: "new user",
              email: email,
              on: serverTimestamp(),
            });

            alert(
              "Please verify your email \nSent to your respective email address"
            );
          });

          navigate("/login");
        }
      })
      .catch((error) => {
        console.error(error.code);
        if (error.code === "auth/email-already-in-use") {
          setEmailExist(true);
        }
      });
  };

  return (
    <>
      <div className="w-full h-screen flex justify-center items-center">
        <div className="border border-blue-500 w-fit rounded-lg p-2 transition-all duration-300">
          <div className="block transition-all duration-300 hover:text-blue-500 tracking-wide text-gray-700 text-lg font-bold mb-3 border-b border-blue-500 cursor-pointer">
            Admin Register
          </div>
          <form className="w-96 max-w-lg" onSubmit={handleRegister}>
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="admin-email"
                >
                  E Mail
                  <span className="text-red-500">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="admin-email"
                  type="email"
                  placeholder="email@admin.com"
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="admin-password"
                >
                  Password
                  <span className="text-red-500">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="password"
                  placeholder="******************"
                  required
                />
              </div>
            </div>

            <div className="flex justify-center flex-wrap w-full mb-1">
              <p className="text-xs  text-red-500 font-bold">
                {emailExist ? "Email Already Exist" : ""}
              </p>
            </div>

            <div className="flex justify-center flex-wrap w-full mb-1 ">
              <div className="w-1/2 px-1 md:px-3">
                <button
                  type="reset"
                  className="block w-full bg-gray-500 text-slate-200 text-center border rounded py-3 leading-tight focus:outline-none cursor-pointer"
                >
                  Reset
                </button>
              </div>
              <div className="w-1/2 px-1 md:px-3">
                <button
                  type="submit"
                  className={`block w-full text-center border rounded py-3 leading-tight focus:outline-none transition-all duration-300 font-bold ${"text-blue-700 border-blue-700 bg-blue-200 cursor-pointer"}`}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
          <Link to="/login" className="item text-sm text-blue-800">
            Registered? <span className="underline">Login Here</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Register;
