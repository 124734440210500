import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import Products from "./Products";

import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  async function handleLogOut() {
    await signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const authChange = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user?.emailVerified) {
          setUser(user);
        } else {
          navigate("/verify");
        }
      } else {
        navigate("/login");
        setUser(null);
      }
    });

    return authChange;
  }, []);

  return (
    <div>
      <Header
        title="Dashboard"
        userMail={user?.email}
        handleLogOut={handleLogOut}
      />
      <div className="m-3 md:m-8 bg-blue-500/30 rounded">
        <Products />
      </div>
    </div>
  );
};

export default Dashboard;
